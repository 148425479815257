<template>
    <div class="modal fade show modal-style" aria-labelledby="addNewCardTitle">
        <div class="modal-dialog modal-dialog-centered" ref="modalRef">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between px-1 my-1 align-item-center">
                            <h1 class="mb-0">{{ title }}</h1>
                            <button
                                @click="onClickClose"
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                    </div>
                </div>

                <hr class="mt-0 mb-0">

                <div class="pb-2">
                    <div class="row py-1 mx-auto">
                        <div class="col-12 col-sm-6 col-md-7 col-lg-7">
                            <label class="form-label" for="serialNo">Contact ID</label>
                            <input
                                placeholder="Please Enter Contact ID"
                                name="serialNo"
                                type="text"
                                v-model.trim="serialNo"
                                class="form-control"
                            />
                        </div>
                        <div v-if="isFilterable" class="col-12 col-sm-6 col-md-5 col-lg-5 roles-segment">
                            <label for="colFormLabel" class="col-form-label">Roles</label>
                            <v-select
                                placeholder="Select Roles"
                                v-model="roleSlug"
                                :options="companyRoles"
                                label="name"
                                :reduce="name => name.value"
                            />
                        </div>
                        <div class="col-4 d-flex align-items-center mt-1">
                            <button @click="onClickToGo" class="btn btn-primary btn-block waves-effect waves-float waves-light">
                                Go
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <Loader v-if="loader"/>
    </div>
</template>

<script setup>
import Loader from "@/components/atom/LoaderComponent";
import handleHospital from '@/services/modules/hospital'
import { inject, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from 'vue-router';

const props = defineProps({
    title: {
        type: String,
        default: 'Filter'
    },
    type: {
        type: String,
        default: 'all'
    },
    onSearchContact: Function,
    isFilterable: {
        type: Boolean,
        default: false
    },
    companyRoles: {
        type: Array,
        default: []
    }
})

const emit = defineEmits(['onSearchContact']);

const { fetchPatientDetailsBySerial } = handleHospital();

const $store = useStore();
const $route = useRoute();
const loading = ref(false);
const loader = ref(false);
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const serialNo = ref('');
const roleSlug = ref('customer');

//Functions and methods
const onClickToGo = async() => {

    if(! serialNo.value) {
        return showError('Please Enter Contact Id')
    }

    if(props.isFilterable && !roleSlug.value) {
        return showError('Please Select Role')
    }

    const query = getQuery();
    loading.value = true;

    await fetchPatientDetailsBySerial(query, serialNo.value).then( async (res) => {
        
        if (!res.status) return showError('Invalid Contact Id')

        const resData = res.data;
        resData.name = resData.full_name;
        emit('onSearchContact', resData);
        onClickClose();
        
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const onClickClose = () => {
    $store.state.isModalOpenThree = false;
}

function getQuery() {
    const type = props.isFilterable ? roleSlug.value : props.type;
    return `?company_id=${$route.params.companyId}&type=${type}`;
}

</script>

<style scoped>
.modal-style {
    display: block; 
    background: #1c1d1c33
}
.roles-segment {
    margin-top: -13px;
}
</style>
